import { Route, Routes } from 'react-router-dom';
import { Header } from '../../components/header';
import { commonRoutes } from '../../shared/helpers/routing';
import { Container } from '../../styles/layout';
import { useAuthToken } from '../../shared/hooks/useAuthToken';

export function NoContextRoutes() {
  const { isInvencoUser } = useAuthToken();
  return (
    <Routes>
      <Route
        index
        element={
          <Container>
            <Header />
          </Container>
        }
      />
      {commonRoutes(isInvencoUser)}
    </Routes>
  );
}
