import { WorkflowScopeType } from '@invenco/common-domain/enums';
import { Route, Routes } from 'react-router-dom';
import { commonRoutes, redirectRoute } from 'shared/helpers/routing';
import { AsnList } from './supply/asns/asn-list/AsnList';
import { AsnDetails } from './supply/asns/asn-details/AsnDetails';
import { InventoryQuantities } from './inventory/quantities/InventoryQuantities';
import { OrderList } from './sales/orders/order-list/OrderList';
import { OrderDetails } from './sales/orders/order-details/OrderDetails';
import { ShippingSettings } from './settings/shipping/ShippingSettings';
import { ShippingProfile } from './settings/shipping-profile/ShippingProfile';
import { SkuList } from './product/sku-list/SkuList';
import { SettingsContainer } from './settings';
import { ChannelList } from './sales/channels/channel-list/ChannelList';
import { ChannelDetails } from './sales/channels/channel-details/ChannelDetails';
import { SkubanaInstall } from './integration/install/SkubanaInstall';
import { SkuDetails } from './product/sku-details/SkuDetails';
import { InsightList } from './analytics/insight-list/InsightList';
import { InsightDetails } from './analytics/insight-details/InsightDetails';
import { WorkflowList } from '../common/workflows/workflow-list/WorkflowList';
import { DeveloperConfig } from '../home/developer-config/DeveloperConfig';
import { WorkflowDetails } from '../common/workflows/workflow-details/WorkflowDetails';
import { useAuthToken } from '../../shared/hooks/useAuthToken';

export function SellerRoutes() {
  const { isInvencoUser } = useAuthToken();

  return (
    <Routes>
      <Route path="/dev-config" element={<DeveloperConfig />} />
      {redirectRoute('/', '/dashboard')}
      {redirectRoute('/dashboard', '/sales/orders')}

      <Route path="/products">
        {redirectRoute('', 'skus')}
        <Route path="skus">
          <Route index element={<SkuList />} />
          <Route path=":id" element={<SkuDetails />} />
        </Route>
      </Route>

      <Route path="/supply">
        {redirectRoute('', 'asns')}
        <Route path="asns">
          <Route index element={<AsnList />} />
          <Route path=":id" element={<AsnDetails />} />
        </Route>
      </Route>

      <Route path="/inventory">
        {redirectRoute('', 'quantities')}
        <Route path="quantities" element={<InventoryQuantities />} />
      </Route>

      <Route path="/sales">
        {redirectRoute('', 'orders')}
        <Route path="orders">
          <Route index element={<OrderList />} />
          <Route path=":id" element={<OrderDetails />} />
        </Route>
        <Route path="channels">
          <Route index element={<ChannelList />} />
          <Route path=":id" element={<ChannelDetails />} />
        </Route>
      </Route>

      <Route path="/analytics">
        {redirectRoute('', 'insights')}
        <Route path="insights">
          <Route index element={<InsightList />} />
          <Route path=":id" element={<InsightDetails />} />
        </Route>
      </Route>

      <Route path="/automation">
        {redirectRoute('', 'workflows')}
        <Route path="workflows">
          <Route index element={<WorkflowList scopeType={WorkflowScopeType.ACCOUNT} />} />
          <Route path=":id" element={<WorkflowDetails scopeType={WorkflowScopeType.ACCOUNT} />} />
        </Route>
      </Route>

      <Route path="/integration">
        <Route path="app/skubana/install" element={<SkubanaInstall />} />
      </Route>

      <Route path="/settings" element={<SettingsContainer />}>
        {redirectRoute('', 'shipping')}
        <Route path="shipping" element={<ShippingSettings />} />
      </Route>
      {/* Pages which don't use the settings subnav must appear outside the /settings route */}
      <Route path="/settings/shipping/profile/:id" element={<ShippingProfile />} />

      {commonRoutes(isInvencoUser)}
    </Routes>
  );
}
