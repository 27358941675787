import styled from 'styled-components';

/**
 * Card sets its min-height to 0 which causes children to flow out of the card when space
 * becomes limited. By wrapping the card in a div, we can override this behavior.
 * You don't get the nice curved radius look at the bottom of the card but you
 * avoid the overflow bug.
 */
export const CardWrapper = styled.div`
  min-height: auto;
`;
