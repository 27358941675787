import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';
import { FeatureFlagGateway, type FeatureFlags } from '../../gateways/FeatureFlagGateway';

type Props = PropsWithChildren<{
  featureFlagGateway: FeatureFlagGateway;
}>;

type FeatureFlagConfigContextValue = {
  featureFlags: FeatureFlags;
  setFeatureFlag: (key: string, value: boolean) => void;
  deleteFeatureFlag: (key: string) => void;
};

const FeatureFlagConfigContext = createContext<FeatureFlagConfigContextValue>({
  featureFlags: {},
  setFeatureFlag: () => {},
  deleteFeatureFlag: () => {},
});

/**
 * This provider is used to manage feature flags in the application.
 * Not to be confused with `FeatureFlagProvider`, which is used to get feature flags
 
* Why is this separate?
 * - `FeatureFlagProvider` should only be used for evaluating flags
 * - `FeatureFlagConfigProvider` is used to managing flags
 */
export function FeatureFlagConfigProvider({ children, featureFlagGateway }: Props) {
  const [featureFlagState, setFeatureFlagState] = useState<FeatureFlags>(
    featureFlagGateway.getAllFlags(),
  );

  const setFeatureFlag = useCallback(
    (key: string, value: boolean) => {
      featureFlagGateway.setFlag(key, value);
      setFeatureFlagState(() => featureFlagGateway.getAllFlags());
    },
    [featureFlagGateway],
  );

  const deleteFeatureFlag = useCallback(
    (key: string) => {
      featureFlagGateway.deleteFlag(key);
      setFeatureFlagState(() => featureFlagGateway.getAllFlags());
    },
    [featureFlagGateway],
  );

  const featureFlagConfigContext: FeatureFlagConfigContextValue = useMemo(
    () => ({
      featureFlags: featureFlagState,
      setFeatureFlag,
      deleteFeatureFlag,
    }),
    [deleteFeatureFlag, featureFlagState, setFeatureFlag],
  );

  return (
    <FeatureFlagConfigContext.Provider value={featureFlagConfigContext}>
      {children}
    </FeatureFlagConfigContext.Provider>
  );
}

export function useFeatureFlagConfig(): FeatureFlagConfigContextValue {
  return useContext(FeatureFlagConfigContext);
}
