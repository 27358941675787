import { Navigate, Route } from 'react-router-dom';
import { DEPLOYMENT_STAGE } from 'env';
import { DeveloperConfig } from '../../pages/home/developer-config/DeveloperConfig';
import { NotFound } from '../../pages/common/not-found/NotFound';
import { Features } from '../../pages/home/features/Features';

export const redirectRoute = (from: string, to: string) => (
  <Route path={from} element={<Navigate to={to} />} />
);

export const developerRoute = ['LOCAL', 'DEV'].includes(DEPLOYMENT_STAGE) ? (
  <Route key="dev" path="/dev-config" element={<DeveloperConfig />} />
) : null;

export const notFoundRoute = <Route key="notFound" path="*" element={<NotFound />} />;

/**
 * These routes are only available to Invenco users in production but are available to all users in
 * LOCAL, DEV and STAGE
 */
export function featureRoute(isInvencoUser: boolean) {
  if (isInvencoUser || DEPLOYMENT_STAGE !== 'PROD') {
    return <Route key="features" path="/features" element={<Features />} />;
  }
  return null;
}

export const commonRoutes = (isInvencoUser = false) => [
  developerRoute,
  notFoundRoute,
  featureRoute(isInvencoUser),
];
