import { AccessContext } from '@invenco/common-interface/auth';
import { PageContainer, RouteContainer } from 'styles/layout';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Navigation } from './navigation/Navigation';
import { SellerRoutes } from '../seller/routes';
import { PlatformRoutes } from '../platform/routes';
import { NoContextRoutes } from '../no-context/routes';
import { WarehouseRoutes } from '../warehouse/routes';
import { useAuthToken } from '../../shared/hooks/useAuthToken';

function HomeImpl() {
  const { accessContext } = useAuthToken();

  const getRoutes = () => {
    switch (accessContext) {
      case AccessContext.SELLER:
        return <SellerRoutes />;
      case AccessContext.PLATFORM:
        return <PlatformRoutes />;
      case AccessContext.WAREHOUSE:
        return <WarehouseRoutes />;
      default:
        return <NoContextRoutes />;
    }
  };

  return (
    <PageContainer>
      <Navigation />
      <RouteContainer>{getRoutes()}</RouteContainer>
    </PageContainer>
  );
}

export const Home = withAuthenticationRequired(HomeImpl);
