import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import { Header } from '../../../components/header';
import { Form } from '../../../components/form';
import { Container, HorizontallySpaced, ListContainer } from '../../../styles/layout';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { CardWrapper } from './styles';
import { Card } from '../../../components/card';
import { useFeatureFlagConfig } from '../../../shared/providers/FeatureFlagConfigProvider';

export function Features() {
  const { featureFlags, setFeatureFlag, deleteFeatureFlag } = useFeatureFlagConfig();

  const [addFeatureFlagForm] = Form.useForm();

  const onFormFinish = (formData: any) => {
    const cleanFeatureFlagKey = formData?.featureFlagKey?.trim();
    if (cleanFeatureFlagKey) {
      setFeatureFlag(cleanFeatureFlagKey, false);
      addFeatureFlagForm.resetFields();
    }
  };

  return (
    <Container>
      <Header title="Features" />
      <ListContainer>
        <CardWrapper>
          <Card title="Frontend Feature Flags" fullHeight>
            <Form
              name="addFeatureFlag"
              form={addFeatureFlagForm}
              onFinish={onFormFinish}
              hideErrors
            >
              <HorizontallySpaced>
                <Form.Item name="featureFlagKey" rules={[{ required: true }]}>
                  <Input placeholder="Add a feature flag" />
                </Form.Item>
                <div>
                  <Button htmlType="submit" type="primary" icon={<PlusOutlined />} />
                </div>
              </HorizontallySpaced>
            </Form>

            {Object.entries(featureFlags).map(([featureFlagKey, enabled]) => (
              <HorizontallySpaced key={featureFlagKey}>
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  type="link"
                  danger
                  onClick={() => {
                    deleteFeatureFlag(featureFlagKey);
                  }}
                />
                <Switch
                  checked={enabled}
                  onChange={(checked) => {
                    setFeatureFlag(featureFlagKey, checked);
                  }}
                />
                <div>{featureFlagKey}</div>
              </HorizontallySpaced>
            ))}
          </Card>
        </CardWrapper>
      </ListContainer>
    </Container>
  );
}
