import { createContext, useCallback, useContext, useMemo, type PropsWithChildren } from 'react';
import { FeatureFlagGateway } from '../../gateways/FeatureFlagGateway';

type Props = PropsWithChildren<{
  featureFlagGateway: FeatureFlagGateway;
}>;

type FeatureFlagContext = {
  /**
   * Returns a boolean represtinging if a feature flag is enabled or disabled
   * If a feature flag is not set, it will return false
   *
   * How to use:
   * ```jsx
   * function MyComponent() {
   *   const { getFeatureFlag } = useFeatureFlags();
   *   const isMyFeatureFlagEnabled = getFeatureFlag('my-feature-flag');
   *   return <div>{isMyFeatureFlagEnabled ? 'Enabled' : 'Disabled'}</div>;
   * }
   * ```
   */
  getFeatureFlag: (key: string) => boolean;
};

const FeatureFlagsContext = createContext<FeatureFlagContext>({
  getFeatureFlag: () => false,
});

export function FeatureFlagProvider({ children, featureFlagGateway }: Props) {
  const getFeatureFlag = useCallback(
    (key: string) => {
      const featureFlag = featureFlagGateway.getFlag(key);
      return featureFlag === true;
    },
    [featureFlagGateway],
  );

  const featureFlagsContext: FeatureFlagContext = useMemo(
    () => ({
      getFeatureFlag,
    }),
    [getFeatureFlag],
  );

  return (
    <FeatureFlagsContext.Provider value={featureFlagsContext}>
      {children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlags(): FeatureFlagContext {
  return useContext(FeatureFlagsContext);
}
