import { LocationInventoryDTO } from '@invenco/common-interface/inventory';
import { SkuType, SkuUnitOfMeasure, UnitDTO, UnitType } from '@invenco/common-interface/products';
import { ListingDTO, ListingStatus, ListingStrategy } from '@invenco/common-interface/sales';
import { MeasurementDTO } from '@invenco/common-interface/shipping';

import { Card } from 'components/card';
import { DetailList } from 'components/detail-list/DetailList';
import { DetailsBlock } from 'components/details-block';
import { DetailsCard } from 'components/details-card';
import { Header } from 'components/header';
import { Label, StatusLabel } from 'components/label';
import { formatMeasurement, formatMoney, formatNumber } from 'shared/helpers';
import { listingStatusType, locationInventoryStatusType } from 'shared/status-maps';
import {
  listingStatusTitle,
  listingStrategyTitle,
  locationInventoryStatusTitle,
  skuTypeTitle,
} from 'shared/title-maps';
import { Container, DetailContainer, MainContainer, SideContainer } from 'styles/layout';
import { Button } from 'components/button';
import { Dropdown } from 'antd';
import { DetailsModal } from './details-modal/DetailsModal';
import { KitComponentModal } from './kit-component-modal/KitComponentModal';
import { MeasurementsModal } from './measurements-modal/MeasurementsModal';
import { PriceModal } from './price-modal/PriceModal';
import { useSkuDetailsPage } from './useSkuDetailsPage';
import { Column, DataTable } from '../../../../components/data-table';
import { ModalConfirm } from '../../../../components/modal-confirm';
import { MysteryComponentModal } from './mystery-component-modal/MysteryComponentModal';
import { ComponentsCard } from './components-card/ComponentsCard';
import { useMysteryComponents } from './useMysteryComponents';

type UnitRow = Omit<UnitDTO, 'id'> & { id: string };

const unitColumns: Column<UnitRow>[] = [
  {
    title: 'Type',
    key: 'unitType',
    render: (type: UnitType) => <Label>{type}</Label>,
  },
  {
    title: 'Measure',
    key: 'unitOfMeasure',
    render: (measure: SkuUnitOfMeasure) => <Label>{measure}</Label>,
  },
  {
    title: 'Qty',
    key: 'baseQuantity',
    render: (qty: number) => formatNumber(qty),
    align: 'right',
  },
  { title: 'Barcode', key: 'barcode', align: 'right' },
  {
    title: 'Dimensions (LxWxH)',
    key: 'dimensions',
    dataIndex: 'measurement',
    render: (measurement?: MeasurementDTO) =>
      measurement
        ? `${measurement.length}x${measurement.width}x${
            measurement.height
          }${measurement.dimensionUnit.toLocaleLowerCase()}`
        : '',
    align: 'right',
  },
  {
    title: 'Weight',
    key: 'weight',
    dataIndex: 'measurement',
    render: (measurement?: MeasurementDTO) =>
      measurement ? `${measurement.weight}${measurement.weightUnit.toLocaleLowerCase()}` : '',
    align: 'right',
  },
];

const inventoryColumns: Column<LocationInventoryDTO>[] = [
  { title: 'Location', key: 'locationName' },
  {
    title: 'Status',
    key: 'status',
    render: (status) => (
      <StatusLabel
        status={status}
        type={locationInventoryStatusType}
        title={locationInventoryStatusTitle}
      />
    ),
  },
  { title: 'Expected', key: 'qtyExpected', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'On Hand', key: 'qtyOnHand', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'Reserved', key: 'qtyReserved', align: 'right', render: (qty) => formatNumber(qty) },
  { title: 'Available', key: 'qtyAvailable', align: 'right', render: (qty) => formatNumber(qty) },
];

const listingColumns: Column<ListingDTO>[] = [
  { title: 'Channel', key: 'channelName' },
  {
    title: 'Status',
    key: 'status',
    render: (status: ListingStatus) => (
      <StatusLabel status={status} type={listingStatusType} title={listingStatusTitle} />
    ),
  },
  {
    title: 'Strategy',
    key: 'strategy',
    render: (strategy: ListingStrategy) => listingStrategyTitle[strategy],
  },
  {
    title: 'Expected',
    key: 'qtyExpected',
    render: (qtyExpected: number) => formatNumber(qtyExpected),
    align: 'right',
  },
  {
    title: 'Available',
    key: 'qtyAvailable',
    render: (qtyAvailable: number) => formatNumber(qtyAvailable),
    align: 'right',
  },
  {
    title: 'Listed',
    key: 'qtyListed',
    render: (qtyListed: number) => formatNumber(qtyListed),
    align: 'right',
  },
];

function MeasurementDetails({
  measurement,
  loading,
}: {
  measurement?: MeasurementDTO;
  loading?: boolean;
}) {
  if (!measurement) return null;
  return (
    <DetailsBlock loading={loading} loadingRows={4}>
      <DetailList
        items={[
          {
            label: 'Length',
            value: `${formatMeasurement(measurement.length, measurement.dimensionUnit)}`,
          },
          {
            label: 'Width',
            value: `${formatMeasurement(measurement.width, measurement.dimensionUnit)}`,
          },
          {
            label: 'Height',
            value: `${formatMeasurement(measurement.height, measurement.dimensionUnit)}`,
          },
          {
            label: 'Weight',
            value: `${formatMeasurement(measurement.weight, measurement.weightUnit)}`,
          },
        ]}
      />
    </DetailsBlock>
  );
}

export function SkuDetails() {
  const {
    models: {
      isLoading,
      isLoadingInventories,
      isLoadingListings,
      isConverting,
      conversionTypes,
      isDetailsModalOpen,
      isPriceModalOpen,
      isMeasurementsModalOpen,
      isKitComponentModalOpen,
      confirmingConversionType,
      canConvert,
      showComponents,
      showInventories,
      showUnits,
      sku,
      inventories,
      listings,
      showAssemblies,
    },
    operations: {
      openDetailsModal,
      closeDetailsModal,
      openPriceModal,
      closePriceModal,
      openMeasurementsModal,
      closeMeasurementsModal,
      startConversion,
      confirmConversion,
      cancelConversion,
      refresh,
      updateSku,
      openKitComponentModal,
      closeKitComponentModal,
      updateKitComponents,
    },
  } = useSkuDetailsPage();
  const {
    models: { isNewMysteryComponentModalOpen, editingMysteryComponent, mysteryComponents },
    operations: {
      addMysteryComponent,
      editMysteryComponent,
      closeMysteryComponentModal,
      deleteMysteryComponent,
      saveMysteryComponent,
    },
  } = useMysteryComponents({ sku });

  return (
    <Container>
      <Header
        title={sku?.name}
        description={sku?.description}
        loading={isLoading}
        loadDescription
        actionContent={
          canConvert && (
            <Dropdown
              trigger={['click']}
              menu={{
                items: conversionTypes.map((type) => ({ key: type, label: skuTypeTitle[type] })),
                onClick: ({ key }) => void startConversion(key as SkuType),
              }}
            >
              <Button type="primary" loading={isConverting}>
                Convert to...
              </Button>
            </Dropdown>
          )
        }
        extraContent={<Label aria-label={`Status: ${sku.type}`}>{sku.type}</Label>}
        links={[
          { url: '/products', title: 'Products' },
          { url: '/products/skus', title: 'SKUs' },
          { url: `/products/skus/${sku?.id}`, title: sku?.name, loading: isLoading },
        ]}
        backURL="/products/skus"
        onRefresh={refresh}
      />
      <DetailContainer>
        <MainContainer>
          {showComponents && (
            <ComponentsCard
              sku={sku}
              mysteryComponents={mysteryComponents}
              editKitComponents={openKitComponentModal}
              addMysteryComponent={addMysteryComponent}
              editMysteryComponent={editMysteryComponent}
              deleteMysteryComponent={deleteMysteryComponent}
            />
          )}

          {showAssemblies && <div>Assemblies</div>}

          {showUnits && (
            <Card title="Units" count={sku.units?.length ?? 0}>
              <DataTable
                simple
                columns={unitColumns}
                rows={(sku.units as UnitRow[]) ?? []}
                loading={isLoading}
              />
            </Card>
          )}

          {showInventories && (
            <Card title="Inventory" count={inventories.length}>
              <DataTable
                simple
                columns={inventoryColumns}
                rows={inventories}
                loading={isLoadingInventories}
              />
            </Card>
          )}

          <Card title="Listings" count={listings.length}>
            <DataTable
              simple
              columns={listingColumns}
              rows={listings}
              loading={isLoadingListings}
            />
          </Card>
        </MainContainer>

        <SideContainer>
          <DetailsCard title="Details" hasEditButton editLabel="Manage" onEdit={openDetailsModal}>
            <DetailsBlock loadingRows={6} loading={isLoading} contentTitle={sku.name}>
              <DetailList
                items={[
                  { label: 'Barcode', value: sku.barcode },
                  { label: 'Type', value: <Label>{sku.type}</Label> },
                  { label: 'Unit Type', value: <Label>{sku.unitType}</Label> },
                  { label: 'Measure', value: <Label>{sku.unitOfMeasure}</Label> },
                  {
                    label: 'Fulfillment Strategy',
                    value: <Label>{sku.fulfillmentStrategy}</Label>,
                  },
                ]}
              />
            </DetailsBlock>
          </DetailsCard>

          <DetailsCard title="Prices" hasEditButton onEdit={openPriceModal}>
            <DetailsBlock loadingRows={2} loading={isLoading}>
              <DetailList
                items={[
                  {
                    label: 'Purchase',
                    value: sku.purchasePrice !== undefined && formatMoney(sku.purchasePrice),
                  },
                  {
                    label: 'Sell',
                    value: sku.sellPrice !== undefined && formatMoney(sku.sellPrice),
                  },
                ]}
              />
            </DetailsBlock>
          </DetailsCard>

          <DetailsCard title="Measurements" hasEditButton onEdit={openMeasurementsModal}>
            <MeasurementDetails measurement={sku.measurement} loading={isLoading} />
          </DetailsCard>

          {sku.verifiedMeasurement && (
            <DetailsCard title="Verified Measurements">
              <MeasurementDetails measurement={sku.verifiedMeasurement} />
            </DetailsCard>
          )}
        </SideContainer>
      </DetailContainer>

      <DetailsModal
        isOpen={isDetailsModalOpen}
        sku={sku}
        updateSku={updateSku}
        onClose={closeDetailsModal}
      />
      <PriceModal
        isOpen={isPriceModalOpen}
        sku={sku}
        updateSku={updateSku}
        onClose={closePriceModal}
      />
      <MeasurementsModal
        isOpen={isMeasurementsModalOpen}
        sku={sku}
        updateSku={updateSku}
        onClose={closeMeasurementsModal}
      />

      {sku?.type === SkuType.KIT && (
        <KitComponentModal
          skuType={sku.type ?? SkuType.STANDARD}
          isOpen={isKitComponentModalOpen}
          onClose={closeKitComponentModal}
          existingComponents={sku.childKitComponents}
          updateKitComponents={updateKitComponents}
        />
      )}
      {sku?.type === SkuType.MYSTERY && (
        <MysteryComponentModal
          open={!!editingMysteryComponent || isNewMysteryComponentModalOpen}
          existingMysteryComponent={editingMysteryComponent}
          allKitComponents={sku.childKitComponents ?? []}
          onSave={saveMysteryComponent}
          onClose={closeMysteryComponentModal}
        />
      )}

      <ModalConfirm
        isSubmitting={isConverting}
        onCancel={cancelConversion}
        onOk={confirmConversion}
        open={!!confirmingConversionType}
        title="Are you sure you want to convert this SKU?"
        description={
          <span>
            Converting the SKU&apos;s type from{' '}
            <strong>{skuTypeTitle[sku?.type ?? '']?.toLocaleLowerCase()}</strong> to{' '}
            <strong>{skuTypeTitle[confirmingConversionType ?? '']?.toLocaleLowerCase()}</strong>{' '}
            will erase all components.
          </span>
        }
      />
    </Container>
  );
}
